body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

figure {
  margin: 0;
}

figcaption {
  font-size: 1rem;
  font-family: 'UntitledSans';
  font-weight: 400;
  line-height: 1.428571429;
  letter-spacing: 0.03333em;
}

@font-face {
  font-display: swap;
  font-family: 'UntitledSansLight';
  font-weight: 300;
  src: url(./fonts/UntitledSans-Light.woff2) format('woff2'),
    url(./fonts/UntitledSans-Light.woff) format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'UntitledSans';
  font-weight: 400;
  src: url(./fonts/UntitledSans-Regular.woff2) format('woff2'),
    url(./fonts/UntitledSans-Regular.woff) format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'UntitledSansMedium';
  font-weight: 500;
  src: url(./fonts/UntitledSans-Medium.woff2) format('woff2'),
    url(./fonts/UntitledSans-Medium.woff) format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'UntitledSerif';
  font-weight: 400;
  src: url(./fonts/UntitledSerif-Regular.woff2) format('woff2'),
    url(./fonts/UntitledSerif-Regular.woff) format('woff');
}
